var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _vm._l(_vm.categories, function (category) {
    return _c('div', {
      key: category.value
    }, [_c('SectionTitle', {
      staticClass: "mb-4",
      attrs: {
        "title": category.title
      }
    }), _c('div', {
      staticClass: "row"
    }, _vm._l(_vm.filteredReports(category), function (report) {
      return _c('div', {
        key: report.type,
        staticClass: "col-md-6 col-xl-3"
      }, [_c('ReportCard', {
        attrs: {
          "report": report
        },
        on: {
          "download": _vm.onClickDownload
        }
      })], 1);
    }), 0)], 1);
  }), _c('ConfirmModal', {
    attrs: {
      "open": !!_vm.modals.download,
      "title": _vm.downloadModalTitle,
      "lg-size": ""
    },
    on: {
      "close": function close($event) {
        _vm.modals.download = false;
      },
      "submit": function submit($event) {
        return _vm.onDownload(_vm.modals.download);
      }
    }
  }, [_vm.modals.download ? _c('div', [_vm.modals.download.confirmDates ? _c('FormGroup', {
    attrs: {
      "id": "confirm-dates",
      "label": "Report Date Range",
      "name": "confirm-dates",
      "type": "dateRangePicker"
    },
    model: {
      value: _vm.options.dateRange,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "dateRange", $$v);
      },
      expression: "options.dateRange"
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.modals.download.params ? _c('div', _vm._l(_vm.modals.download.params, function (param, key) {
    return _c('FormGroup', {
      key: key,
      attrs: {
        "id": key,
        "label": param.label,
        "name": key,
        "type": param.type,
        "options": param.options,
        "description": param.description
      },
      model: {
        value: _vm.options[key],
        callback: function callback($$v) {
          _vm.$set(_vm.options, key, $$v);
        },
        expression: "options[key]"
      }
    });
  }), 1) : _vm._e()])], 2);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "alert alert-warning"
  }, [_c('strong', [_vm._v("Please note!")]), _vm._v(" This area is being phased out. Some reports may redirect you to the new reports area. This can also be accessed by clicking "), _c('strong', {
    staticClass: "mx-1"
  }, [_c('i', {
    staticClass: "fa fa-file-chart-pie mr-1"
  }), _vm._v(" Reports")]), _vm._v(" in the side menu bar. ")]);
}]

export { render, staticRenderFns }