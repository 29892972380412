<template>
  <div class="ReportCard d-flex align-items-center mb-5" @click="onClickDownload(report)">
    <div class="ReportCard-icon">
      <i v-if="report.format === 'pdf'" class="fad text-danger fa-file-pdf fa-4x"></i>
      <i v-else-if="report.format === 'excel'" class="fad duotone-etainabl fa-file-excel fa-4x"></i>
      <i v-else-if="report.format === 'csv'" class="fad duotone-etainabl fa-file-csv fa-4x"></i>
      <i v-else class="fad duotone-etainabl fa-file fa-4x"></i>
    </div>
    <div class="ml-3">
      <span v-if="report.companyId" class="mr-2 font-size-sm font-w600 text-warning" title="This report is only visible to users in your company"
        >Custom Report</span
      >
      <span v-if="report.adminOnly" class="mr-2 font-size-sm font-w600 text-danger">ADMIN</span>
      <span v-if="report.isBeta" class="mr-2 font-size-sm font-w600 text-info" title="This report is still being developed">BETA</span>
      <h5 class="mb-1 flex-grow-1">{{ report.name }} <span v-if="report.isNew" class="badge badge-fix badge-success ml-1">NEW</span></h5>
      <p class="ReportCard-description font-w600 text-muted mb-0">{{ report.description }}</p>
    </div>
    <!-- <div class="block-header">

    <div class="block-content bg-body-light text-center px-4 ReportCard-body">
      <h3 class="font-size-h4 font-w700 mb-1">
      </h3>
      <h4 class="font-size-h6 text-muted mb-3">{{ report.description }}</h4>
    </div>
    <div class="block-content block-content-full">
      <div class="row">
        <div v-if="report.canDownload" class="col">
          <button class="btn btn-block btn-primary" :data-cy="`${report.name}-download-btn`.toLowerCase()" @click="onClickDownload(report)">
            <i class="fa fa-download mr-1 opacity-50"></i> Download
          </button>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  name: 'ReportCard',
  props: {
    report: {
      type: Object,
      required: true
    }
  },
  methods: {
    onClickDownload(report) {
      this.$emit('download', report);
    },
    onClickView(report) {
      this.$emit('view', report);
    }
  }
};
</script>
<style lang="scss">
.ReportCard {
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    z-index: -1;
  }

  &:hover::after {
    padding: 6px 12px;
    margin: -12px -12px 14px -12px;
    background-color: #f7f7fa;
  }

  &-body {
    height: 120px;
  }

  &-description {
    line-height: 20px;
  }
}
</style>
