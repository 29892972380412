<template>
  <div>
    <div class="alert alert-warning">
      <strong>Please note!</strong> This area is being phased out. Some reports may redirect you to the new reports area. This can also be accessed by
      clicking <strong class="mx-1"><i class="fa fa-file-chart-pie mr-1"></i> Reports</strong> in the side menu bar.
    </div>
    <div v-for="category in categories" :key="category.value">
      <SectionTitle class="mb-4" :title="category.title" />
      <div class="row">
        <div v-for="report in filteredReports(category)" :key="report.type" class="col-md-6 col-xl-3">
          <ReportCard :report="report" @download="onClickDownload" />
        </div>
      </div>
    </div>
    <ConfirmModal
      :open="!!modals.download"
      :title="downloadModalTitle"
      lg-size
      @close="modals.download = false"
      @submit="onDownload(modals.download)"
    >
      <div v-if="modals.download">
        <FormGroup
          v-if="modals.download.confirmDates"
          id="confirm-dates"
          v-model="options.dateRange"
          label="Report Date Range"
          name="confirm-dates"
          type="dateRangePicker"
        />
      </div>
      <div v-if="modals.download.params">
        <FormGroup
          v-for="(param, key) in modals.download.params"
          :id="key"
          :key="key"
          v-model="options[key]"
          :label="param.label"
          :name="key"
          :type="param.type"
          :options="param.options"
          :description="param.description"
        />
      </div>
    </ConfirmModal>
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import ConfirmModal from '@/components/ConfirmModal';
import FormGroup from '@/components/FormGroup';
import ReportCard from '@/components/reports/ReportCard';
import SectionTitle from '@/components/base/SectionTitle';

const apiFormat = 'DD/MM/YYYY';
const longFormat = 'DD MMM YYYY';
const todayDate = moment();
const todayLastYear = moment().subtract(1, 'years');
const startDate = moment(todayLastYear, longFormat).format(apiFormat);
const endDate = moment(todayDate, longFormat).format(apiFormat);

export default {
  name: 'AssetReportList',
  components: {
    ConfirmModal,
    FormGroup,
    ReportCard,
    SectionTitle
  },
  data() {
    return {
      modals: {
        remove: false,
        download: false
      },
      options: {
        startDate: startDate,
        endDate: endDate,
        dateRange: [todayLastYear, todayDate]
      },
      filter: '',
      search: '',
      categories: [
        { title: 'PDF Reports', value: 'pdf' },
        { title: 'Data Exports', value: 'data' },
        { title: 'Consumption Reports', value: 'consumption' },
        { title: 'Miscellaneous Reports', value: 'misc' }
      ]
    };
  },
  computed: {
    ...mapGetters({
      asset: 'asset/asset',
      accounts: 'asset/accounts'
    }),
    downloadModalTitle() {
      if (!this.modals.download) return 'N/A';
      return `Download ${this.modals.download.name} Report`;
    },
    subMeters() {
      return this.accounts.reduce(
        (meters, account) => [
          ...meters,
          ...(account.subMeters || []).map(subMeter => ({ label: `[${account.meterPointNumber}] ${subMeter.name}`, value: subMeter._id }))
        ],
        []
      );
    },
    reports() {
      return [
        {
          name: 'Consumption (Summary)',
          type: 'asset-summary',
          description: 'A single-page report showing consumption and emissions',
          canDownload: true, // TODO
          canView: false,
          format: 'pdf',
          category: 'pdf',
          isBeta: true,
          isNew: true,
          confirmDates: true,
          size: 'large',
          template: '668e87deea8a1302cc32a47e'
        },
        {
          name: 'Consumption (Detailed)',
          type: 'asset-detailed',
          description: 'Detailed and customizable report for consumption, emissions, cost and other data',
          canDownload: true, // TODO
          canView: false,
          format: 'pdf',
          category: 'pdf',
          isBeta: true,
          isNew: true,
          confirmDates: true,
          size: 'large',
          params: {
            summary: {
              label: 'Summary',
              default: true,
              options: [
                { label: 'Yes', value: true },
                { label: 'No', value: false }
              ],
              type: 'radio',
              description: 'Summary information about the asset including key certifications such as EPC rating'
            },
            images: {
              label: 'Images',
              default: true,
              options: [
                { label: 'Yes', value: true },
                { label: 'No', value: false }
              ],
              type: 'radio',
              description: 'A page displaying a mixture of images from satellites and street view level'
            },
            emissions: {
              label: 'Emissions',
              default: true,
              options: [
                { label: 'Yes', value: true },
                { label: 'No', value: false }
              ],
              type: 'radio',
              description: 'Detailed emissions data including scope breakdown and intensity'
            },
            consumptionSummary: {
              label: 'Consumption (Summary Page)',
              default: true,
              options: [
                { label: 'Yes', value: true },
                { label: 'No', value: false }
              ],
              type: 'radio',
              description: 'A single page displaying consumption summaries for the asset'
            },
            consumptionDetailed: {
              label: 'Consumption (Detailed Pages)',
              default: true,
              options: [
                { label: 'Yes', value: true },
                { label: 'No', value: false }
              ],
              type: 'radio',
              description: 'Detailed page for each utility (electricity, gas etc)'
            },
            dataQuality: {
              label: 'Data Quality',
              default: true,
              options: [
                { label: 'Yes', value: true },
                { label: 'No', value: false }
              ],
              type: 'radio',
              description: 'Data quality information'
            },
            epc: {
              label: 'EPC Report',
              default: true,
              options: [
                { label: 'Yes', value: true },
                { label: 'No', value: false }
              ],
              type: 'radio',
              description: 'A summary of EPC certificates for this asset'
            },
            projects: {
              label: 'Projects',
              default: true,
              options: [
                { label: 'Yes', value: true },
                { label: 'No', value: false }
              ],
              type: 'radio',
              description: 'A summary of projects for this asset'
            },
            landlordTenant: {
              label: 'Landlord/Tenant',
              default: true,
              options: [
                { label: 'Yes', value: true },
                { label: 'No', value: false }
              ],
              type: 'radio',
              description: 'A breakdown of landlord/tenant data'
            },
            notes: {
              label: 'Report Notes',
              default: '',
              type: 'textarea',
              description: 'Enter any notes you wish to appear on the report'
            }
          },
          template: '668fa9d928afed73669d39b7'
        },
        {
          name: 'Accounts',
          type: 'asset-accounts-table',
          description: 'An export of all the accounts associated with this asset',
          canDownload: true,
          canView: false,
          confirmDates: true,
          format: 'xlsx',
          category: 'data'
        },
        {
          name: 'Accounts Status',
          type: 'accounts-status',
          description: 'An export of all accounts displaying status data',
          canDownload: true,
          canView: false,
          confirmDates: false,
          format: 'xlsx',
          category: 'data'
        },
        {
          name: 'Contracts',
          type: 'accounts-contracts',
          description: 'An export of all the contracts associated with this asset',
          canDownload: true,
          canView: false,
          confirmDates: true,
          format: 'xlsx',
          category: 'data'
        },
        {
          name: 'Invoices',
          type: 'asset-invoices',
          description: 'An export of all the invoices associated with this asset',
          canDownload: true,
          canView: false,
          confirmDates: true,
          format: 'xlsx',
          category: 'data'
        },
        {
          name: 'Consolidated Consumption',
          type: 'consolidated-consumption',
          description: 'Invoice and smart data consumption combined into one report',
          canDownload: true,
          canView: false,
          confirmDates: true,
          format: 'xlsx',
          params: {},
          category: 'consumption',
          template: '65a70e25ef26ad53c9ab713c'
        },
        {
          name: 'Consumption Summary',
          type: 'asset-consumption-table',
          description: 'A summary of consumption and cost data for each account',
          canDownload: true,
          canView: false,
          confirmDates: true,
          format: 'xlsx',
          params: {
            sourceData: {
              label: 'Data Source',
              default: 'invoice',
              options: [
                { label: 'Invoices', value: 'invoice' },
                { label: 'Readings', value: 'reading' },
                { label: 'Smart Data', value: 'consumption' }
              ],
              type: 'radio',
              description: 'Choose which data set to use for this report'
            },
            emissions: {
              label: 'Show Emission Data',
              default: false,
              options: [
                { label: 'No', value: false },
                { label: 'Yes', value: true }
              ],
              type: 'radio',
              description: 'Display emission data in the report'
            },
            simulated: {
              label: 'Display Simulated Consumption',
              default: false,
              options: [
                { label: 'No', value: false },
                { label: 'Yes', value: true }
              ],
              type: 'radio',
              description: 'Choose which data set to use for this report'
            },
            dayNightSplit: {
              label: 'Day/Night Split',
              default: false,
              options: [
                { label: 'No', value: false },
                { label: 'Yes', value: true }
              ],
              type: 'radio',
              description: 'Choose whether to split out day and night consumption'
            },
            likeForLike: {
              label: 'Like-for-like',
              default: true,
              options: [
                { label: 'Yes', value: true },
                { label: 'No', value: false }
              ],
              type: 'radio',
              description: 'Show which data is like for like based on the below baseline year'
            },
            baseline: {
              label: 'Baseline Year (For like-for-like)',
              default: true,
              options: [
                moment().subtract(1, 'year').year(),
                moment().subtract(2, 'year').year(),
                moment().subtract(3, 'year').year(),
                moment().subtract(4, 'year').year(),
                moment().subtract(5, 'year').year()
              ].map(year => ({ label: year, value: year })),
              type: 'select',
              description: 'Show which data is like for like based on the below baseline year'
            }
          },
          category: 'consumption'
        },
        {
          name: 'Consumption (Monthly)',
          type: 'asset-consumption-detailed-table',
          format: 'xlsx',
          description: 'Consumption and cost data for each account with monthly breakdown',
          canDownload: true,
          canView: false,
          confirmDates: true,
          template: '65a70e25ef26ad53c9ab713c',
          params: {
            sourceData: {
              label: 'Data Source',
              default: 'invoice',
              options: [
                { label: 'Invoices', value: 'invoice' },
                { label: 'Readings', value: 'reading' },
                { label: 'Smart Data', value: 'consumption' }
              ],
              type: 'radio',
              description: 'Choose which data set to use for this report'
            },
            emissions: {
              label: 'Show Emission Data',
              default: false,
              options: [
                { label: 'No', value: false },
                { label: 'Yes', value: true }
              ],
              type: 'radio',
              description: 'Display emission data in the report'
            },
            simulated: {
              label: 'Display Simulated Consumption',
              default: false,
              options: [
                { label: 'No', value: false },
                { label: 'Yes', value: true }
              ],
              type: 'radio',
              description: 'Choose which data set to use for this report'
            },
            dayNightSplit: {
              label: 'Day/Night Split',
              default: false,
              options: [
                { label: 'No', value: false },
                { label: 'Yes', value: true }
              ],
              type: 'radio',
              description: 'Choose whether to split out day and night consumption'
            }
          },
          category: 'consumption'
        },
        {
          name: 'Consumption (Like-for-like)',
          type: 'asset-consumption-lfl',
          format: 'xlsx',
          description: 'Consumption and data using a baseline and showing like-for-like data',
          canDownload: true,
          canView: false,
          confirmDates: true,
          params: {
            baseline: {
              label: 'Baseline Year',
              default: moment().subtract(1, 'year').year(),
              options: [
                moment().subtract(1, 'year').year(),
                moment().subtract(2, 'year').year(),
                moment().subtract(3, 'year').year(),
                moment().subtract(4, 'year').year(),
                moment().subtract(5, 'year').year()
              ].map(year => ({ label: year, value: year })),
              type: 'select',
              description: 'Show which data is like for like based on the below baseline year'
            },
            emissions: {
              label: 'Show Emission Data',
              default: false,
              options: [
                { label: 'No', value: false },
                { label: 'Yes', value: true }
              ],
              type: 'radio',
              description: 'Display emission data in the report'
            },
            simulated: {
              label: 'Display Simulated Consumption',
              default: false,
              options: [
                { label: 'No', value: false },
                { label: 'Yes', value: true }
              ],
              type: 'radio',
              description: 'Choose which data set to use for this report'
            }
          },
          category: 'consumption'
        },
        {
          name: 'Data Quality',
          type: 'asset-data-quality-table',
          description: 'Missing invoices, readings and other data for this asset',
          canDownload: true,
          canView: false,
          confirmDates: true,
          format: 'xlsx',
          category: 'misc'
        },
        {
          name: 'Projects Summary',
          type: 'asset-projects',
          description: 'Status of projects',
          canDownload: true,
          canView: false,
          confirmDates: true,
          format: 'xlsx',
          category: 'data'
        },
        {
          name: 'Missing Account Data',
          type: 'accounts-missing-data',
          description: 'Missing data on accounts',
          canDownload: true,
          canView: false,
          confirmDates: false,
          format: 'xlsx',
          category: 'data'
        },
        {
          name: 'Missing Asset Data',
          type: 'asset-missing-data',
          description: 'Missing data on asset',
          canDownload: true,
          canView: false,
          confirmDates: false,
          format: 'xlsx',
          category: 'data'
        },
        {
          name: 'Discussion Board Comments',
          type: 'asset-discussion-comments',
          description: 'View all discussion board comments',
          canDownload: true,
          canView: false,
          confirmDates: true,
          format: 'xlsx',
          category: 'data'
        },
        {
          name: 'Scrapers',
          type: 'accounts-scrapers',
          description: 'Accounts with scrapers enabled',
          canDownload: true,
          canView: false,
          confirmDates: true,
          format: 'xlsx',
          category: 'misc'
        },
        {
          name: 'EPC',
          type: 'asset-epc',
          description: 'Summary of all EPC certificates',
          canDownload: true,
          canView: false,
          confirmDates: false,
          format: 'xlsx',
          category: 'misc'
        }
      ];
    }
  },
  mounted() {
    this.getAccounts({ id: this.$route.params.id, data: { params: { $sort: 'name:1' } } });
  },
  methods: {
    ...mapActions({
      downloadReport: 'report/download',
      getAccounts: 'asset/accounts'
    }),
    isAuthorized(report) {
      if (this.$auth.isAdmin || (!report.companyId && !report.authRoles)) return true;

      if (!report.authRoles) return report.companyId === this.$auth.companyId;

      if (!report.companyId) return report.authRoles.some(role => this.$auth.roles.includes(role));

      return report.companyId === this.$auth.companyId && report.authRoles.some(role => this.$auth.roles.includes(role));
    },
    filteredReports(category) {
      const reports = this.reports.filter(report => report.category === category.value && this.isAuthorized(report));
      reports.sort((a, b) => a.name.localeCompare(b.name));
      return reports;
    },
    onClickDownload(report) {
      // ignore if greenchip
      if (report.template && this.$auth.companyId !== '5ff855f8d19326001546e9e4') {
        return this.$router.push({
          name: 'reports-generate',
          query: { template: report.template }
        });
      }

      if (report.params) {
        Object.entries(report.params || {}).forEach(([key, value]) => {
          this.$set(this.options, key, typeof value.default === 'undefined' ? '' : value.default);
        });
      }

      this.modals.download = report;
    },
    onDownload(report) {
      let query = null;

      query = {
        startDate: moment(this.options.dateRange[0]).format(apiFormat),
        endDate: moment(this.options.dateRange[1]).format(apiFormat),
        format: report.format
      };

      if (report.params) {
        Object.keys(report.params)
          .filter(key => key !== 'notes')
          .forEach(key => {
            if (typeof this.options[key] !== 'undefined') {
              query[key] = this.options[key];
            }
          });
      }

      this.modals.download = false;

      this.$router.push({
        name: 'asset-reports-download',
        params: { reportType: report.type, format: report.format },
        query
      });
    }
  }
};
</script>
