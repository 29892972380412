var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ReportCard d-flex align-items-center mb-5",
    on: {
      "click": function click($event) {
        return _vm.onClickDownload(_vm.report);
      }
    }
  }, [_c('div', {
    staticClass: "ReportCard-icon"
  }, [_vm.report.format === 'pdf' ? _c('i', {
    staticClass: "fad text-danger fa-file-pdf fa-4x"
  }) : _vm.report.format === 'excel' ? _c('i', {
    staticClass: "fad duotone-etainabl fa-file-excel fa-4x"
  }) : _vm.report.format === 'csv' ? _c('i', {
    staticClass: "fad duotone-etainabl fa-file-csv fa-4x"
  }) : _c('i', {
    staticClass: "fad duotone-etainabl fa-file fa-4x"
  })]), _c('div', {
    staticClass: "ml-3"
  }, [_vm.report.companyId ? _c('span', {
    staticClass: "mr-2 font-size-sm font-w600 text-warning",
    attrs: {
      "title": "This report is only visible to users in your company"
    }
  }, [_vm._v("Custom Report")]) : _vm._e(), _vm.report.adminOnly ? _c('span', {
    staticClass: "mr-2 font-size-sm font-w600 text-danger"
  }, [_vm._v("ADMIN")]) : _vm._e(), _vm.report.isBeta ? _c('span', {
    staticClass: "mr-2 font-size-sm font-w600 text-info",
    attrs: {
      "title": "This report is still being developed"
    }
  }, [_vm._v("BETA")]) : _vm._e(), _c('h5', {
    staticClass: "mb-1 flex-grow-1"
  }, [_vm._v(_vm._s(_vm.report.name) + " "), _vm.report.isNew ? _c('span', {
    staticClass: "badge badge-fix badge-success ml-1"
  }, [_vm._v("NEW")]) : _vm._e()]), _c('p', {
    staticClass: "ReportCard-description font-w600 text-muted mb-0"
  }, [_vm._v(_vm._s(_vm.report.description))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }